<!-- 自营外协司机编辑弹窗 -->
<template>
  <el-dialog
    width="40%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'编辑司机':'新增司机'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px">
      <el-form-item label="姓名:" prop="realname">
        <el-input
          clearable
          v-model="form.realname"
          placeholder="请输入姓名"/>
      </el-form-item>
      <el-form-item label="手机号:" prop="phone">
        <el-input
          clearable
          v-model="form.phone"
          placeholder="请输入手机号"/>
      </el-form-item>
      <el-form-item label="车牌号:" prop="license_plate_number">
        <el-input
          clearable
          v-model="form.license_plate_number"
          placeholder="请输入车牌号"/>
      </el-form-item>
    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">提交
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

import {Addoutsource_driver, Editoutsource_driver} from "@/api/yunli";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        realname: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        license_plate_number: [
          {required: true, message: '请输入车牌号', trigger: 'blur'}
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            Addoutsource_driver(this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            let data = this.form;
            Editoutsource_driver(data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
.sijilist{
  .sijilistactive{
    cursor: pointer;
    display: inline-block;
    width: 64px;
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    color: #868792;
    margin: 10px;
  }
  .sijilistactive1{
    color: #FF9B05;
    border-bottom: 4px solid #FF9B05;
  }
}

.sijiradio{

}
.listactive{
  width: 100%;
  border: 1px solid #ebecf2;
  border-radius: 6px;
  padding: 20px;
  margin-top: 15px;
}
.listactive1{
  border: 1px solid #fea837;
}
.diyixiang:first-child{
  margin-top: 0 !important;
}

</style>
